@import 'tailwindcss/base';
@import 'tailwindcss/components';

/* Additional styles */
@import 'additional-styles/utility-patterns.css';
@import 'additional-styles/theme.css';
@import 'additional-styles/3d-img.css';

@import 'tailwindcss/utilities';

/* Additional Tailwind directives: https://tailwindcss.com/docs/functions-and-directives/#responsive */
@layer utilities {
    .rtl {
        direction: rtl;
    }
}