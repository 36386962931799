.form-input:focus,
.form-textarea:focus,
.form-multiselect:focus,
.form-select:focus,
.form-checkbox:focus,
.form-radio:focus {
    @apply ring-0;
}

/* Hamburger button */
.hamburger svg>*:nth-child(1),
.hamburger svg>*:nth-child(2),
.hamburger svg>*:nth-child(3) {
    transform-origin: center;
    transform: rotate(0deg);
}

.hamburger svg>*:nth-child(1) {
    transition: y 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), opacity 0.1s ease-in;
}

.hamburger svg>*:nth-child(2) {
    transition: transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger svg>*:nth-child(3) {
    transition: y 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), width 0.1s 0.25s ease-in;
}

.hamburger.active svg>*:nth-child(1) {
    opacity: 0;
    y: 9;
    transform: rotate(225deg);
    transition: y 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.1s 0.12s ease-out;
}

.hamburger.active svg>*:nth-child(2) {
    transform: rotate(225deg);
    transition: transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.active svg>*:nth-child(3) {
    y: 9;
    transform: rotate(135deg);
    transition: y 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), width 0.1s ease-out;
}

/* Custom Swiper styles */
.swiper-button-disabled {
    @apply opacity-50 cursor-default pointer-events-none;
}

.stellar-carousel .swiper-slide {
    @apply opacity-80;
}

.stellar-carousel .swiper-slide.swiper-slide-active {
    @apply opacity-100;
}

/* Pulsing animation */
@keyframes pulseLoop {
    0% {
        opacity: 0;
        transform: scale(.25) translateZ(0);
    }

    30% {
        opacity: .4;
    }

    70% {
        opacity: 0;
    }

    80% {
        transform: scale(1) translateZ(0);
    }
}

.pulse {
    opacity: 0;
    transform-origin: center;
    animation: pulseLoop 12000ms linear infinite;
}

.pulse-1 {
    animation-delay: -4000ms;
}

.pulse-2 {
    animation-delay: -8000ms;
}

.translate-z-0 {
    transform: translateZ(0);
}

/* Custom AOS distance */
@media screen {
    html:not(.no-js) body [data-aos=fade-up] {
        -webkit-transform: translate3d(0, 14px, 0);
        transform: translate3d(0, 14px, 0);
    }

    html:not(.no-js) body [data-aos=fade-down] {
        -webkit-transform: translate3d(0, -14px, 0);
        transform: translate3d(0, -14px, 0);
    }

    html:not(.no-js) body [data-aos=fade-right] {
        -webkit-transform: translate3d(-14px, 0, 0);
        transform: translate3d(-14px, 0, 0);
    }

    html:not(.no-js) body [data-aos=fade-left] {
        -webkit-transform: translate3d(14px, 0, 0);
        transform: translate3d(14px, 0, 0);
    }

    html:not(.no-js) body [data-aos=fade-up-right] {
        -webkit-transform: translate3d(-14px, 14px, 0);
        transform: translate3d(-14px, 14px, 0);
    }

    html:not(.no-js) body [data-aos=fade-up-left] {
        -webkit-transform: translate3d(14px, 14px, 0);
        transform: translate3d(14px, 14px, 0);
    }

    html:not(.no-js) body [data-aos=fade-down-right] {
        -webkit-transform: translate3d(-14px, -14px, 0);
        transform: translate3d(-14px, -14px, 0);
    }

    html:not(.no-js) body [data-aos=fade-down-left] {
        -webkit-transform: translate3d(14px, -14px, 0);
        transform: translate3d(14px, -14px, 0);
    }

    html:not(.no-js) body [data-aos=zoom-in-up] {
        -webkit-transform: translate3d(0, 14px, 0) scale(.6);
        transform: translate3d(0, 14px, 0) scale(.6);
    }

    html:not(.no-js) body [data-aos=zoom-in-down] {
        -webkit-transform: translate3d(0, -14px, 0) scale(.6);
        transform: translate3d(0, -14px, 0) scale(.6);
    }

    html:not(.no-js) body [data-aos=zoom-in-right] {
        -webkit-transform: translate3d(-14px, 0, 0) scale(.6);
        transform: translate3d(-14px, 0, 0) scale(.6);
    }

    html:not(.no-js) body [data-aos=zoom-in-left] {
        -webkit-transform: translate3d(14px, 0, 0) scale(.6);
        transform: translate3d(14px, 0, 0) scale(.6);
    }

    html:not(.no-js) body [data-aos=zoom-out-up] {
        -webkit-transform: translate3d(0, 14px, 0) scale(1.2);
        transform: translate3d(0, 14px, 0) scale(1.2);
    }

    html:not(.no-js) body [data-aos=zoom-out-down] {
        -webkit-transform: translate3d(0, -14px, 0) scale(1.2);
        transform: translate3d(0, -14px, 0) scale(1.2);
    }

    html:not(.no-js) body [data-aos=zoom-out-right] {
        -webkit-transform: translate3d(-14px, 0, 0) scale(1.2);
        transform: translate3d(-14px, 0, 0) scale(1.2);
    }

    html:not(.no-js) body [data-aos=zoom-out-left] {
        -webkit-transform: translate3d(14px, 0, 0) scale(1.2);
        transform: translate3d(14px, 0, 0) scale(1.2);
    }
}