.img-3d {
    width: 25rem;
    height: 30rem;
    margin: 3rem auto;
    /* transform: rotateX(25deg) rotateY(25deg) rotateZ(-20deg);
    transition: transform 0.5s ease-in-out;
    border-bottom: 0.4rem solid rgb(45, 45, 45);
    border-left: 0.4rem solid rgb(50, 50, 50); */
    border-radius: 2rem 0.5rem;
}

.img-3d.right-3d {
    /*transform: rotateX(25deg) rotateY(-25deg) rotateZ(20deg);
    border-left: 0;
    border-right: 0.4rem solid rgb(50, 50, 50); */
    border-radius: 0.5rem 2rem;

}

@media (max-width: 767px) {
    .img-3d {
        width: 25rem;
        height: 30rem;
        margin: 3rem auto;
        /*transform: rotateX(0) rotateY(0) rotateZ(0);
        border-bottom: 0;
        border-left: 0; */
        border-radius: 1rem 1rem;
    }
}