@keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
}

.cursor {
    color: white;
    display: inline-block;
    margin-left: 1px;
    animation: blink 1s step-end infinite;
}